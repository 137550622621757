import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

const navigatorGeolocation = () => {
	return new Promise((resolve, reject) => {
		return navigator.geolocation.getCurrentPosition(resolve, reject, options);
	});
};

/**
 * @param {Object} options
 * @return {Object}
 */
export const getPosition = async (options = {}) => {
	const isPwa = Capacitor.getPlatform() !== 'web';

	return !isPwa
		? await navigatorGeolocation()
		: await Geolocation.getCurrentPosition({
				enableHighAccuracy: true,
				...options
		  });
};
