import { isNil } from '@/shared/utils';
import { mapFilters } from '@/shared/map-filters';
import { getDiffInMins, getLateSignIndicator, isArrived, isStarted } from '@/modules/transports/utils';

export default {
	name: 'RendDetailsTransportMixin',
	data() {
		return {
			defTime: '0001-01-01T00:00:00Z'
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isStarted() {
			if (isNil(this.transport)) return false;
			return isStarted(this.transport);
		},

		/**
		 * @return {Boolean}
		 */
		isArrived() {
			if (isNil(this.transport)) return false;
			return isArrived(this.transport);
		},

		/**
		 * @return {Boolean}
		 */
		isLate() {
			if (isNil(this.transport)) return false;
			const { effDepartureTime, departureTime } = this.transport;
			return new Date(effDepartureTime) > new Date(departureTime);
		},

		/**
		 * @return {Boolean}
		 */
		isArrivedLate() {
			if (isNil(this.transport)) return false;
			const { effArrivalTime, arrivalTime } = this.transport;
			return new Date(effArrivalTime) > new Date(arrivalTime);
		},

		/**
		 * @return {Boolean}
		 */
		willArriveLate() {
			if (isNil(this.transport)) return false;
			const { arrivalTime, eta } = this.transport;
			return new Date(eta) > new Date(arrivalTime);
		},

		/**
		 * @return {Boolean}
		 */
		willArriveInAdvance() {
			if (isNil(this.transport)) return false;
			const { arrivalTime, eta } = this.transport;
			return new Date(eta) < new Date(arrivalTime);
		},

		/**
		 * @return {Boolean}
		 */
		isMoreThanFiveMin() {
			if (isNil(this.transport)) return false;
			const { effDepartureTime, departureTime } = this.transport;
			const diff = getDiffInMins(departureTime, effDepartureTime);
			return diff > 5;
		},

		surplusDuration() {
			const { departureTime, effDepartureTime } = this.transport;
			return this.datesDiff(departureTime, effDepartureTime);
		},

		rendSurplusStartDepartureDuration() {
			if (!this.isStarted) return '';
			const { departureTime, effDepartureTime } = this.transport;
			const _p = getLateSignIndicator(departureTime, effDepartureTime);
			return _p + this.surplusDuration;
		}
	},
	methods: mapFilters(['datesDiff'])
};
