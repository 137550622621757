var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.transport
            ? _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", [
                    _c("div", { staticClass: "content_sb_middle full" }, [
                      _c("h4", [_vm._v(_vm._s(_vm.rendPageTitle))]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _vm.isStarted && !_vm.isArrived
                            ? _c(
                                "span",
                                { staticStyle: { "margin-right": "15px" } },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("millisToMinutesAndSeconds")(
                                          _vm.timer
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { size: "sm" },
                                      on: { click: _vm.launchManualRefresh },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "bi bi-arrow-repeat",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "16",
                                            height: "16",
                                            fill: "currentColor",
                                            viewBox: "0 0 16 16",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d: "M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: "tooltip-target-1",
                                    size: "sm",
                                    disabled: _vm.isStarted || _vm.isArrived,
                                  },
                                  on: { click: _vm.handleStartEffTime },
                                },
                                [_c("i", { staticClass: "icon-plane" })]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: "tooltip-target-2",
                                    size: "sm",
                                    disabled: !_vm.isStarted && _vm.isArrived,
                                  },
                                  on: { click: _vm.handleEndEffTime },
                                },
                                [_c("i", { staticClass: "icon-flag" })]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "tooltip-target-1",
                                triggers: "hover",
                                placement: "bottomleft",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(78657865, "Start trip")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "tooltip-target-2",
                                triggers: "hover",
                                placement: "bottomleft",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(897786, "End effective time")
                                  ) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            { staticClass: "content_sb_middle" },
                            [
                              _c("b-col", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(53677, "From")) + ": "
                                  ),
                                ]),
                                _c("span", [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.fromAddressString)),
                                  ]),
                                ]),
                              ]),
                              _c("b-col", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(536877, "To")) + ": "
                                  ),
                                ]),
                                _c("span", [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.toAddressString)),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "b-col",
                                [
                                  !_vm.isStarted && !_vm.isArrived
                                    ? _c(
                                        "b-badge",
                                        { attrs: { variant: "info" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  12412414,
                                                  "Not started yet"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isStarted
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(90878, "Started at")
                                              ) +
                                              ": \n\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "b-badge",
                                            { attrs: { variant: "success" } },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm._f("fromNowToHuman")(
                                                      _vm.transport
                                                        .effDepartureTime
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isArrived
                                    ? _c(
                                        "b-badge",
                                        { attrs: { variant: "success" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(12414, "Arrived at")
                                              ) +
                                              ": \n\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(_vm.transport.arrivalTime)
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pull_end" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "info" },
                                      on: { click: _vm.handleOpenNewWindow },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(598377, "Map")) +
                                          " \n\t\t\t\t\t\t\t\t\t"
                                      ),
                                      _c("i", { staticClass: "icon-map" }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "push_t_40" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "full",
                                  attrs: { show: "", variant: "default" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.$has(_vm.transport)
                                        ? _c("b-col", [
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    789789,
                                                    "Transport's type"
                                                  )
                                                ) + ": "
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.rendTrasnportMean)
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _c("b-col", [
                                        _c("small", [
                                          _vm._v(
                                            _vm._s(_vm.distanceUnit) + ": "
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.transport.km)),
                                        ]),
                                      ]),
                                      _vm.$has(_vm.transport.kgCoTwo)
                                        ? _c("b-col", [
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(47689, "kgCoTwo")
                                                ) + ": "
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.rendKgCoTwoVariant(
                                                  _vm.transport.kgCoTwo
                                                ),
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.transport.kgCoTwo)
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "push_t_20" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "full",
                                  attrs: {
                                    show: "",
                                    variant: _vm.rendEffTimeAlertVariant,
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.$has(_vm.transport.departureTime)
                                        ? _c(
                                            "b-col",
                                            [
                                              _vm.isStarted
                                                ? _c("i", {
                                                    staticClass: "icon-plane",
                                                  })
                                                : _vm._e(),
                                              _c("small", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1242,
                                                        "Depart"
                                                      )
                                                    ) +
                                                    ": "
                                                ),
                                              ]),
                                              _c("DepartureTimeTypo", {
                                                attrs: {
                                                  transport: _vm.transport,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.$has(_vm.transport.arrivalTime)
                                        ? _c(
                                            "b-col",
                                            [
                                              _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      12842,
                                                      "Arrival"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                              _c("ArrivalTimeTypo", {
                                                attrs: {
                                                  transport: _vm.transport,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "push_t_40" },
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "full" },
                              [
                                _vm.$has(_vm.transport.user)
                                  ? _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          title: _vm.FormMSG(55677, "Driver"),
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(57897, "name")
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.transport.user.name
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(577, "firstname")
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.transport.user.firstName
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  789078,
                                                  "transport mean"
                                                )
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.transport.transportMean
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "full push_t_40" },
                              [
                                _vm.$has(_vm.vehicules)
                                  ? _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          title: _vm.FormMSG(677, "Vehicules"),
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(57897, "name")
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.vehicules.name
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  57678897,
                                                  "numberPlate"
                                                )
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.vehicules.numberPlate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  57898897,
                                                  "kgCoTwoPerKm"
                                                )
                                              ) + ": "
                                            ),
                                          ]),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.vehicules.kgCoTwoPerKm
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "b-col",
                            [
                              _vm.$has(_vm.passengersList)
                                ? _c(
                                    "b-card",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          5675677,
                                          "Passengers"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "list-container" },
                                        _vm._l(
                                          _vm.passengersList,
                                          function (passenger, i) {
                                            return _c(
                                              "li",
                                              { key: i },
                                              [
                                                _c("b-card", [
                                                  _c("span", [
                                                    _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            57897,
                                                            "name"
                                                          )
                                                        ) + ": "
                                                      ),
                                                    ]),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("capitalize")(
                                                            passenger.name
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("br"),
                                                  _c("span", [
                                                    _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            577,
                                                            "firstname"
                                                          )
                                                        ) + ": "
                                                      ),
                                                    ]),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("capitalize")(
                                                            passenger.firstName
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("br"),
                                                  _c("span", [
                                                    _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            576787,
                                                            "phone"
                                                          )
                                                        ) + ": "
                                                      ),
                                                    ]),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `tel:${passenger.phone}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              passenger.phone
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-telephone",
                                                        }),
                                                      ]
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "push_t_40" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12" } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "transport-extraInfo",
                                  disabled: "",
                                  placeholder: _vm.FormMSG(
                                    342342,
                                    "Transport's extra info..."
                                  ),
                                  rows: "3",
                                  required: "",
                                },
                                model: {
                                  value: _vm.transport.extraInfo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.transport, "extraInfo", $$v)
                                  },
                                  expression: "transport.extraInfo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }