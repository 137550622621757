var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("\n\t" + _vm._s(_vm.rendArrivalTime) + "\n\t"),
    _vm.isStarted && !_vm.isArrived
      ? _c(
          "b",
          {
            class: {
              "text-danger": _vm.willArriveLate,
              "text-success": _vm.willArriveInAdvance,
            },
          },
          [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.rendSurplusEstimationArrivalDuration) +
                "\n\t"
            ),
          ]
        )
      : _vm._e(),
    _vm.isArrived
      ? _c(
          "span",
          { staticStyle: { "margin-left": "10px" } },
          [
            _c(
              "b-badge",
              { attrs: { variant: _vm.isArrivedLate ? "danger" : "success" } },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("fromNowToHuman")(_vm.transport.effArrivalTime)
                    ) +
                    "\n\t\t"
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }