var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("\n\t" + _vm._s(_vm.rendDepartureTime) + "\n\t"),
    _vm.isStarted && _vm.isMoreThanFiveMin
      ? _c("b", { class: { "text-danger": _vm.isLate } }, [
          _vm._v(
            "\n\t\t" + _vm._s(_vm.rendSurplusStartDepartureDuration) + "\n\t"
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }