<template>
	<span>
		{{ rendArrivalTime }}
		<b
			v-if="isStarted && !isArrived"
			:class="{
				'text-danger': willArriveLate,
				'text-success': willArriveInAdvance
			}"
		>
			{{ rendSurplusEstimationArrivalDuration }}
		</b>
		<span v-if="isArrived" style="margin-left: 10px">
			<b-badge :variant="isArrivedLate ? 'danger' : 'success'">
				{{ transport.effArrivalTime | fromNowToHuman }}
			</b-badge>
		</span>
	</span>
</template>

<script>
import { mapFilters } from '@/shared/map-filters';
import { getLateSignIndicator } from '@/modules/transports/utils';
import rendDetailsMixin from '@/modules/transports/rendDetails.transport.mixin';

export default {
	name: 'DepartTimeTypoComponent',
	mixins: [rendDetailsMixin],
	props: {
		transport: {
			type: Object,
			required: true,
			default: () => {}
		},
		dateFormat: {
			type: String,
			required: false,
			default: 'fromNowToHuman',
			validator: (v) => ['formatTime', 'fromNowToHuman'].includes(v)
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendSurplusEstimationArrivalDuration() {
			if (!this.isStarted) return '';
			const { arrivalTime, eta } = this.transport;
			const _p = getLateSignIndicator(arrivalTime, eta);
			const __ = _p === '- ' ? '' : _p;
			return __ + this.datesDiff(arrivalTime, eta);
		},

		/**
		 * @return {String}
		 */
		rendArrivalTime() {
			return this[this.dateFormat](this.transport.arrivalTime);
		}
	},
	methods: mapFilters(['formatTime', 'fromNowToHuman', 'datesDiff'])
};
</script>
