<template>
	<errorContainer :error="error" :warning="warning">
		<transition name="slide">
			<b-card v-if="transport" no-body>
				<b-card-header>
					<div class="content_sb_middle full">
						<h4>{{ rendPageTitle }}</h4>

						<div class="right">
							<span v-if="isStarted && !isArrived" style="margin-right: 15px">
								<b>{{ timer | millisToMinutesAndSeconds }}</b>
								<b-button style="margin-left: 10px" size="sm" @click="launchManualRefresh">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-arrow-repeat"
										viewBox="0 0 16 16"
									>
										<path
											d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
										/>
										<path
											fill-rule="evenodd"
											d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
										/>
									</svg>
								</b-button>
							</span>
							<b-button-group>
								<b-button id="tooltip-target-1" size="sm" :disabled="isStarted || isArrived" @click="handleStartEffTime">
									<i class="icon-plane" />
								</b-button>
								<b-button id="tooltip-target-2" size="sm" :disabled="!isStarted && isArrived" @click="handleEndEffTime">
									<i class="icon-flag" />
								</b-button>
							</b-button-group>

							<b-tooltip target="tooltip-target-1" triggers="hover" placement="bottomleft">
								{{ FormMSG(78657865, 'Start trip') }}
							</b-tooltip>
							<b-tooltip target="tooltip-target-2" triggers="hover" placement="bottomleft">
								{{ FormMSG(897786, 'End effective time') }}
							</b-tooltip>
						</div>
					</div>
				</b-card-header>

				<b-card-body>
					<b-card>
						<b-row class="content_sb_middle">
							<b-col>
								<span>{{ FormMSG(53677, 'From') }}: </span>
								<span>
									<b>{{ fromAddressString }}</b>
								</span>
							</b-col>
							<b-col>
								<span>{{ FormMSG(536877, 'To') }}: </span>
								<span>
									<b>{{ toAddressString }}</b>
								</span>
							</b-col>
							<b-col>
								<b-badge v-if="!isStarted && !isArrived" variant="info">
									{{ FormMSG(12412414, 'Not started yet') }}
								</b-badge>
								<span v-if="isStarted">
									{{ FormMSG(90878, 'Started at') }}:&ensp;
									<b-badge variant="success">
										{{ transport.effDepartureTime | fromNowToHuman }}
									</b-badge>
								</span>

								<!-- <span v-if="isStarted">
                  {{ FormMSG(98976, 'Last known position') }}:&ensp;
                  <b>{{ lastKnownPositionAddressString }}</b>
                </span> -->
								<b-badge v-if="isArrived" variant="success">
									{{ FormMSG(12414, 'Arrived at') }}:&ensp;
									<b>{{ transport.arrivalTime }}</b>
								</b-badge>
							</b-col>
							<b-col class="pull_end">
								<b-button variant="info" @click="handleOpenNewWindow">
									{{ FormMSG(598377, 'Map') }}&ensp;
									<i class="icon-map" />
								</b-button>
								<!-- <b-button
                  id="tooltip-target-show-trackingpositio-map"
                  variant="primary"
                  @click="isTrackingModalOpen = true"
                >
                  {{ FormMSG(598377, 'Map') }}&ensp;
                  <i class="icon-map" />
                </b-button> -->
							</b-col>
						</b-row>
					</b-card>

					<b-row class="push_t_40">
						<b-col>
							<b-alert class="full" show variant="default">
								<b-row>
									<b-col v-if="$has(transport)">
										<small>{{ FormMSG(789789, "Transport's type") }}: </small>
										<span>{{ rendTrasnportMean }}</span>
									</b-col>
									<b-col>
										<small>{{ distanceUnit }}: </small>
										<span>{{ transport.km }}</span>
									</b-col>
									<b-col v-if="$has(transport.kgCoTwo)">
										<small>{{ FormMSG(47689, 'kgCoTwo') }}: </small>
										<span :class="rendKgCoTwoVariant(transport.kgCoTwo)">{{ transport.kgCoTwo }}</span>
									</b-col>
								</b-row>
							</b-alert>
						</b-col> </b-row
					><b-row class="push_t_20">
						<b-col>
							<b-alert class="full" show :variant="rendEffTimeAlertVariant">
								<b-row>
									<b-col v-if="$has(transport.departureTime)">
										<i v-if="isStarted" class="icon-plane" />
										<small> {{ FormMSG(1242, 'Depart') }}: </small>
										<DepartureTimeTypo :transport="transport" />
									</b-col>

									<b-col v-if="$has(transport.arrivalTime)">
										<small>{{ FormMSG(12842, 'Arrival') }}: </small>
										<ArrivalTimeTypo :transport="transport" />
									</b-col>
								</b-row>
							</b-alert>
						</b-col>
					</b-row>

					<b-row class="push_t_40">
						<b-col>
							<div class="full">
								<b-card v-if="$has(transport.user)" :title="FormMSG(55677, 'Driver')">
									<span>
										<small>{{ FormMSG(57897, 'name') }}: </small>
										<b>{{ transport.user.name | capitalize }}</b>
									</span>
									<br />
									<span>
										<small>{{ FormMSG(577, 'firstname') }}: </small>
										<b>{{ transport.user.firstName | capitalize }}</b>
									</span>
									<br />
									<span>
										<small>{{ FormMSG(789078, 'transport mean') }}: </small>
										<b>{{ transport.transportMean | capitalize }}</b>
									</span>
								</b-card>
							</div>
							<div class="full push_t_40">
								<b-card v-if="$has(vehicules)" :title="FormMSG(677, 'Vehicules')">
									<span>
										<small>{{ FormMSG(57897, 'name') }}: </small>
										<b>{{ vehicules.name | capitalize }}</b>
									</span>
									<br />
									<span>
										<small>{{ FormMSG(57678897, 'numberPlate') }}: </small>
										<b>{{ vehicules.numberPlate | capitalize }}</b>
									</span>
									<br />
									<span>
										<small>{{ FormMSG(57898897, 'kgCoTwoPerKm') }}: </small>
										<b>{{ vehicules.kgCoTwoPerKm | capitalize }}</b>
									</span>
								</b-card>
							</div>
						</b-col>
						<b-col>
							<b-card v-if="$has(passengersList)" :title="FormMSG(5675677, 'Passengers')">
								<ul class="list-container">
									<li v-for="(passenger, i) in passengersList" :key="i">
										<b-card>
											<span>
												<small>{{ FormMSG(57897, 'name') }}: </small>
												<b>{{ passenger.name | capitalize }}</b>
											</span>
											<br />
											<span>
												<small>{{ FormMSG(577, 'firstname') }}: </small>
												<b>{{ passenger.firstName | capitalize }}</b>
											</span>
											<br />
											<span>
												<small>{{ FormMSG(576787, 'phone') }}: </small>
												<a :href="`tel:${passenger.phone}`">
													{{ passenger.phone }}
													<i class="icon-telephone" />
												</a>
											</span>
										</b-card>
									</li>
								</ul>
							</b-card>
						</b-col>
					</b-row>

					<b-row class="push_t_40">
						<b-col sm="12">
							<b-form-textarea
								id="transport-extraInfo"
								v-model="transport.extraInfo"
								disabled
								:placeholder="FormMSG(342342, 'Transport\'s extra info...')"
								rows="3"
								required
							/>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>

			<!-- v-if="isStarted || !isArrived" -->
			<!-- <TrackingOnGoogleMapModal
        v-model="isTrackingModalOpen"
        :options="trackingMapViewOptions"
        :curr-user-pos.sync="currUserPos"
      /> -->
		</transition>
	</errorContainer>
</template>

<script>
import moment from 'moment';
import { isNil, isObj, getObjectFromValue } from '@/shared/utils';
import { getPosition } from '@/shared/geolocation';
import { fetchDistance } from '~helpers';
import { makeDirLink } from '@/shared/google/helpers';
import { mapFilters } from '@/shared/map-filters';
import { addressFormatter } from '@/shared/fomaters';
import { getDiffInMins, getLateSignIndicator, quickSave, getTravelModeFromTransportMean } from '@/modules/transports/utils';

import { getTransport, getPassengers } from '@/cruds/transports.crud';
import { getVehicule } from '@/cruds/vehicules.crud';
import { getAddress } from '@/cruds/address.crud';

import languageMessages from '@/mixins/languageMessages';
import rendDetailsMixin from '@/modules/transports/rendDetails.transport.mixin';

// import TrackingOnGoogleMapModal from '@/modules/transports/Modals/TrackingOnGoogleMapModal'
import DepartureTimeTypo from '@/modules/transports/components/Typos/DepartureTime';
import ArrivalTimeTypo from '@/modules/transports/components/Typos/ArrivalTime';
import { store } from '@/store';

const now = new Date();
const defaultTimer = 300000;

export default {
	name: 'MyTransportFocusPage',
	components: { DepartureTimeTypo, ArrivalTimeTypo },
	mixins: [languageMessages, rendDetailsMixin],
	data() {
		return {
			defTime: '0001-01-01T00:00:00Z',
			error: {},
			warning: '',
			transport: null,
			isTrackingModalOpen: false,
			rendAddressSelected: null,

			fromAddressString: '',
			toAddressString: '',

			passengersList: null,
			vehicules: null,

			estimationArrival: '',

			timer: defaultTimer,
			currUserPos: {
				lat: 0,
				lng: 0
			},
			lastKnownPositionAddressString: '',

			trackingMapViewOptions: {}
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendPageTitle() {
			const _t = this.FormMSG(11, 'Transport:');
			return `${_t} ${this.transport.id}`;
		},

		/**
		 * @return {Array}
		 */
		optionsForTransportMean() {
			let result = this.FormMenu(905); // 1124 is without planes

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		/**
		 * @return {String}
		 */
		rendTrasnportMean() {
			if (isNil(this.optionsForTransportMean) || isNil(this.transport)) return '...';
			const mean = getObjectFromValue(this.optionsForTransportMean, 'value', parseInt(this.transport.type));
			return isNil(mean) ? '...' : mean.text;
		},
		/**
		 * @return {String}
		 */
		rendEffTimeAlertVariant() {
			if (this.isStarted && this.isMoreThanFiveMin) return 'danger';
			return 'default';
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	watch: {
		/**
		 * @param {Boolean} status
		 */
		isStarted(status) {
			if (!status) return;
			this.getUserPosition();
		},

		timer: {
			handler(value) {
				if (value === 0) {
					this.getUserPosition();
					this.timer = defaultTimer;
				}
				setTimeout(() => {
					this.timer = this.timer - 1000;
				}, 1000);
			},
			immediate: true
		},

		currUserPos: {
			handler(position) {
				this.refreshEstimation(position);
				this.saveCurrUserPosition(position);
				// this.setLastKnownPositionAddressString(position)
			},
			deep: true
		}
	},
	async created() {
		await this.initTransport();
		this.rendAddressText();
		this.initDurationEstimation();
	},
	beforeDestroy() {
		this.launchManualRefresh();
	},
	methods: {
		...mapFilters(['datesDiff']),

		initDurationEstimation() {
			if (isNil(this.transport)) return;
			if (!this.isStarted || this.isArrived) return;
			this.getCommuteTime({
				from: this.transport.fromAddressId,
				to: this.transport.toAddressId,
				departureTime: this.transport.effDepartureTime
			});
		},

		/**
		 * @param {Object} position
		 */
		async saveCurrUserPosition(position) {
			await quickSave(this.transport.id, {
				curLat: position.lat,
				curLng: position.lng
			});
		},

		async initTransport() {
			this.transport = await getTransport(this.$route.params.id);
			this.rendAddressText(this.transport);
			this.loadPassengers();
			this.loadVehicule();
		},

		async loadPassengers() {
			if (isNil(this.transport)) return;
			const passengers = await getPassengers(this.transport.id);
			this.passengersList = passengers;
		},

		async loadVehicule() {
			this.vehicules = isNil(this.transport) || isNil(this.transport.vehiculeId) ? [] : await getVehicule(this.transport.vehiculeId);
		},

		async handleOpenNewWindow() {
			const item = this.transport;
			const moved = item.curLat !== 0 && item.curLng !== 0;

			const origin = this.isStarted && moved ? { lat: item.curLat, lng: item.curLng } : await getAddress(item.fromAddressId);
			const destination = await getAddress(item.toAddressId);
			const { travelMode } = getTravelModeFromTransportMean(this.transport.type);

			return makeDirLink(origin, destination, {
				windowTitle: this.FormMSG(213421, 'Google map link'),
				travelMode
			});
		},

		/**
		 * @param {String|Number} kg
		 * @return {String}
		 */
		rendKgCoTwoVariant(kg) {
			if (kg > 10) return 'text-danger';
			return kg < 1 ? 'text-success' : 'text-info';
		},

		/**
		 * @param {Object} destinationPayload
		 */
		async getCommuteTime({ from, to, departureTime }) {
			const fromAddress = isObj(from) ? from : await getAddress(from);
			const options = {
				from: fromAddress,
				to: await getAddress(to),
				departureTime: departureTime | new Date(),
				travelMode: 'DRIVING',
				avoidHighways: true,
				avoidTolls: true
			};

			const res = await fetchDistance(options);
			const row = res.rows[0];
			const elem = row.elements[0];
			if (isNil(elem)) return;

			const durationInSeconds = elem.duration.value;

			const update = await quickSave(this.transport.id, {
				eta: moment(now).add(durationInSeconds, 'seconds')
			});

			this.transport = Object.assign(this.transport, update);
		},

		async getUserPosition() {
			if (!this.isStarted) return;
			const { coords } = await getPosition();
			this.currUserPos = {
				lat: coords.latitude,
				lng: coords.longitude
			};

			// if (this.$isPwa()){
			//   console.log('getUserPosition_IOS')
			//   // const coordinates = await Geolocation.getCurrentPosition()
			//   // console.log({ IOS_coordinates: coordinates })
			//   // vm.currUserPos = {
			//   //   lat: coordinates.latitude,
			//   //   lng: coordinates.longitude
			//   // }
			//   Geolocation.getCurrentPosition().then(function (geoPosition) {
			//     console.log({ geoPosition })
			//     vm.currUserPos = {
			//       lat: geoPosition.coords.latitude,
			//       lng: geoPosition.coords.longitude
			//     }
			//   })
			// } else {
			//   navigator.geolocation.getCurrentPosition(function(position) {
			//     vm.currUserPos = {
			//       lat: position.coords.latitude,
			//       lng: position.coords.longitude
			//     }
			//   })
			// }
		},

		launchManualRefresh() {
			this.getUserPosition();
			this.timer = defaultTimer;
		},

		// /**
		//  * @param {Object} position
		//  */
		// async setLastKnownPositionAddressString({ lat, lng }) {
		//   const addr = await getReverseGeocodingData(lat, lng)
		//   console.log({ setLastKnownPositionAddressString: addr })
		//   this.lastKnownPositionAddressString = addr
		// },

		/**
		 * @param {Object} position
		 */
		async refreshEstimation(position) {
			const options = {
				from: position,
				to: this.transport.toAddressId,
				departureTime: new Date()
			};
			await this.getCommuteTime(options);
		},

		/**
		 * @param {Number} addressId
		 */
		async addressTextRender(addressId) {
			if (isNil(addressId) || addressId === 0) return;

			const _a = await getAddress(addressId);
			return addressFormatter(_a);
		},

		async rendAddressText(transport) {
			if (isNil(transport)) return;
			this.fromAddressString = await this.addressTextRender(transport.fromAddressId);
			this.toAddressString = await this.addressTextRender(transport.toAddressId);
		},

		async handleStartEffTime() {
			const res = await quickSave(this.transport.id, {
				effDepartureTime: new Date()
			});
			this.transport = Object.assign(this.transport, res);
		},

		async handleEndEffTime() {
			const res = await quickSave(this.transport.id, {
				effArrivalTime: new Date()
			});
			this.transport = Object.assign(this.transport, res);
		}
	}
};
</script>
