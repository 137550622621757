<template>
	<span>
		{{ rendDepartureTime }}
		<b v-if="isStarted && isMoreThanFiveMin" :class="{ 'text-danger': isLate }">
			{{ rendSurplusStartDepartureDuration }}
		</b>
	</span>
</template>

<script>
import { mapFilters } from '@/shared/map-filters';
import { getLateSignIndicator } from '@/modules/transports/utils';
import rendDetailsMixin from '@/modules/transports/rendDetails.transport.mixin';

export default {
	name: 'DepartTimeTypoComponent',
	mixins: [rendDetailsMixin],
	props: {
		transport: {
			type: Object,
			required: true,
			default: () => {}
		},
		dateFormat: {
			type: String,
			required: false,
			default: 'fromNowToHuman',
			validator: (v) => ['formatTime', 'fromNowToHuman'].includes(v)
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendSurplusStartDepartureDuration() {
			if (!this.isStarted) return '';
			const { departureTime, effDepartureTime } = this.transport;
			const _p = getLateSignIndicator(departureTime, effDepartureTime);
			return _p + this.surplusDuration;
		},

		/**
		 * @return {String}
		 */
		rendDepartureTime() {
			return this[this.dateFormat](this.transport.departureTime);
		}
	},
	methods: mapFilters(['formatTime', 'fromNowToHuman'])
};
</script>
